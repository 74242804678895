import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Button, Flex, notification } from "antd";
import { useNavigate } from "react-router-dom";

import { BASE_URL } from "../../constants";
import CustomTable from "../../components/Table";
import { CHAINS_ID_TO_NAME_MAPPING } from "../../chains";

const CHAIN_NAMES = Object.values(CHAINS_ID_TO_NAME_MAPPING).map((name) =>
  name.toLowerCase()
);

const getRewardsDistributionList = () =>
  fetch(`${BASE_URL}job_list`).then((res) => res.json());

const RewardsDistributionList = () => {
  const [api, contextHolder] = notification.useNotification();
  const [list, setList] = useState();
  const [creatingByChainName, setCreatingByChainName] = useState<string>("");

  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ["rewards_distribution_list"],
    queryFn: getRewardsDistributionList,
  });

  useEffect(() => {
    if (!query || !query.data || !query.data.result) {
      return;
    }

    const list =
      query.data.result.map((item: string) => {
        const infos = item.split("/");
        const chainDateInfo = infos[infos.length - 1];
        const [chainName, year, month, day] = chainDateInfo
          .slice(0, -5)
          .split("_");

        const action = (
          <Button
            onClick={() => {
              navigate(`/action/distribute_rewards/detail/${chainDateInfo}`);
            }}
          >
            View History
          </Button>
        );

        return {
          chainName,
          date: `${year}/${month}/${day}`,
          action,
        };
      }) ?? [];
    setList(list);
  }, [query?.data?.result?.length]);

  const onCreate = async (chainName: string) => {
    try {
      const res = await fetch(
        `${BASE_URL}create_distribute_job?chain=${chainName}`
      );
      if (res.status !== 200) {
        throw new Error();
      }
      const curYear = new Date().getFullYear();
      const curMonth = new Date().getMonth() + 1;
      const curDay = new Date().getDate();
      const fileId = `${chainName}_${curYear}_${curMonth}_${curDay}.json`;
      api.success({
        message: `Create distribution process successfully`,
      });
      navigate(`/action/distribute_rewards/detail/${fileId}`);
    } catch (e) {
      api.error({
        message: `You have already distributed this chain today`,
        description: "ignore thie button",
      });
    } finally {
      setCreatingByChainName("");
    }
  };

  return (
    <div>
      <h1>Rewards Distribution List</h1>
      <Flex gap="10px" style={{ marginBottom: "20px" }} justify="center">
        {CHAIN_NAMES.map((name) => (
          <Button
            type="primary"
            key={name}
            onClick={() => {
              onCreate(name);
            }}
          >
            Distribute {name} Rewards
          </Button>
        ))}
      </Flex>
      <CustomTable isLoading={query.isLoading} data={list} />
      {contextHolder}
    </div>
  );
};

export default RewardsDistributionList;
