import { GoogleLogin, type CredentialResponse } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { notification, Image, Flex } from "antd";
import useLocalStorage from "use-local-storage";

import LoginImg from "../assets/login.jpg";
import { USER_EMAIL_EXP, USER_EMAIL_ADDRESS } from "../constants";

const EMAIL_WHITELIST = [
  "znj472982642@gmail.com",
  "lily95.chenll@gmail.com",
  "kester@bytetradelab.io",
  "rs@alphamesh.xyz",
  "lili@alphamesh.xyz",
  "naijia@alphamesh.xyz",
  "lili@capybaralabs.xyz",
  "rs@capybaralabs.xyz",
  "nj@capybaralabs.xyz",
];

export default function Login() {
  const [api, contextHolder] = notification.useNotification();
  const [, setUserExp] = useLocalStorage(USER_EMAIL_EXP, "");
  const [, setUseEmailAddr] = useLocalStorage(USER_EMAIL_ADDRESS, "");

  const openFailed = () => {
    api.error({
      message: `Goole Login Failed`,
      description: "Why failed? Please try again later",
    });
  };

  const onSuccess = (credentialResponse: CredentialResponse) => {
    try {
      if (credentialResponse.credential) {
        const userInfo = jwtDecode(credentialResponse.credential) as Record<
          string,
          any
        >;
        if (!EMAIL_WHITELIST.includes(userInfo.email)) {
          setUseEmailAddr("");
          setUserExp("");
          api.error({
            message: `Goole Login Failed`,
            description: "No access to the website",
          });
          return;
        }
        setUseEmailAddr(userInfo.email as string);
        setUserExp(userInfo.exp);
      } else {
        throw new Error();
      }
    } catch (e) {
      api.error({
        message: `Goole Login Failed`,
        description: "Contact Lili to check google auth setting",
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Flex
        style={{ height: "100vh" }}
        justify="center"
        align="center"
        vertical
      >
        <h3>Login Login Login</h3>
        <Image
          width={200}
          src={LoginImg}
          alt="login"
          style={{ marginBottom: "20px" }}
        />
        <GoogleLogin
          onSuccess={onSuccess}
          onError={openFailed}
          auto_select
          useOneTap
        />
      </Flex>
    </>
  );
}
