import React, { useContext, useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { notification } from "antd";
// @ts-expect-error no ts declaration
import _ from "lodash";

import { FilterContext } from "../../Context";
import { getAddressLabel, getTokenAddress, getClient, fetchAllData } from "../utils";
import CustomTable from "../../components/Table";

// take ktc ranking as an example
const Ktc: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();

  const { chainId } = useContext(FilterContext);
  const [isLoading, setIsLoading] = useState(false);
  const [finalData, setFinalData] = useState<any[] | undefined>();

  const tokenAddress = getTokenAddress("KTC", chainId);
  const client = getClient(chainId);

  useEffect(() => {
    if (!tokenAddress || !client) return;
    try {
      const fetchData = async () => {
        const query = gql`
          query allKtc($token: String!, $skip: Int!) {
            stakeGmxes(
              where: { token: $token }
              first: 1000
              orderBy: timestamp
              orderDirection: asc
              skip: $skip
            ) {
              account
              amount
            }
          }
        `;

        setIsLoading(true);
        const stakeRes = await fetchAllData(client, query, "stakeGmxes", {
          token: tokenAddress,
        });

        const balances = _.chain(stakeRes)
          .groupBy((item: Record<string, any>) => item.account)
          .mapValues((item: Record<string, any>[]) =>
            item.reduce((prev, cur) => prev + parseInt(cur?.amount ?? 0, 10), 0)
          )
          .value();

        const unstakeQuery = gql`
          query allKtc($token: String!, $skip: Int!) {
            unstakeGmxes(
              where: { token: $token }
              first: 1000
              orderBy: timestamp
              orderDirection: asc
              skip: $skip
            ) {
              account
              amount
            }
          }
        `;
        const unstakeRes = await fetchAllData(
          client,
          unstakeQuery,
          "unstakeGmxes",
          {
            token: tokenAddress,
          }
        );

        unstakeRes.forEach((record) => {
          if (!balances[record.account]) {
            balances[record.account] = 0;
          }
          balances[record.account] -= parseInt(record.amount, 10);
        });

        const finalTableData = Object.entries(balances).map((entry) => ({
          account: entry[0],
          mark: getAddressLabel(entry[0]),
          amount: (entry[1] as number) / 1e18,
        }));

        setFinalData(finalTableData);
        setIsLoading(false);
      };
      fetchData();
    } catch (error) {
      api.error({
        message: `Failed in [Ktc component]`,
        // @ts-expect-error any type
        description: error?.message ?? error,
      });
    }
  }, [tokenAddress, client]);

  return (
    <div>
      <h1> Used params - | Chain |</h1>
      <CustomTable isLoading={isLoading} data={finalData} />
      {contextHolder}
    </div>
  );
};

export default Ktc;
