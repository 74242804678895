import React, { useState } from "react";
import type { MenuProps } from "antd";
import {
  Layout,
  Menu,
  theme,
  Flex,
  Select,
  Input,
  DatePicker,
  Button,
} from "antd";
import { Outlet, Link } from "react-router-dom";
import useLocalStorage from "use-local-storage";

import { FilterContext } from "../Context";
import { SUPPORTED_CHAINS } from "../chains";
import { USER_EMAIL_EXP } from "../constants";
import { NAV_ITEMS } from "./MenuRoutes";
import Login from "./Login";

const { Header, Content, Sider } = Layout;

const navItems: MenuProps["items"] = Object.entries(NAV_ITEMS).map((entry) => ({
  key: entry[0],
  label: entry[0],

  children: Object.entries(entry[1]).map((subEntry) => ({
    key: subEntry[0],
    label: <Link to={subEntry[1] as string}>{subEntry[0]}</Link>,
  })),
}));

const Root: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [chainId, setChainId] = useState<number>();
  const [address, setAddress] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [filterContext, setFilterContext] = useState({});
  const [userExp] = useLocalStorage(USER_EMAIL_EXP, "");

  const onUpdate = () => {
    setFilterContext({
      chainId,
      address,
      dateFrom: dateFrom ? new Date(dateFrom).getTime() : undefined,
      dateTo: dateTo ? new Date(dateTo).getTime() : undefined,
    });
  };

  if (
    !userExp ||
    parseInt(userExp, 10) < Math.trunc(new Date().getTime() / 1000)
  ) {
    return <Login />;
  }

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Flex justify="center">
          <h3 style={{ color: "white" }}>KTX Admin Portal</h3>
        </Flex>
        <Menu
          theme="dark"
          mode="inline"
          items={navItems}
          defaultOpenKeys={[navItems[0]!.key as string]}
        />
      </Sider>
      <Layout style={{ marginLeft: 200, height: "100vh" }}>
        <Header
          style={{
            padding: "0 16px",
            background: colorBgContainer,
            height: "fit-content",
          }}
        >
          <Flex gap="middle" align="center" justify="center" wrap="wrap">
            <Flex gap="small" align="center">
              <div>Chain Id:</div>
              <Select
                style={{ minWidth: 120 }}
                onChange={(value) => {
                  setChainId(parseInt(value, 10));
                }}
                options={Object.entries(SUPPORTED_CHAINS).map((entry) => ({
                  value: entry[1],
                  label: entry[0],
                }))}
              />
            </Flex>
            <Flex gap="small" align="center">
              <div>Address:</div>
              <Input
                placeholder="e.g. 0x1212121212121212"
                style={{ minWidth: 200 }}
                onChange={(e) => {
                  setAddress(e.currentTarget.value);
                }}
              />
            </Flex>
            <Flex gap="small" align="center">
              <div>Date From: </div>
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime={true}
                onChange={(_, dateString) => {
                  setDateFrom(dateString as string);
                }}
              />
            </Flex>
            <Flex gap="small" align="center">
              <div>Date To: </div>
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime={true}
                onChange={(_, dateString) => {
                  setDateTo(dateString as string);
                }}
              />
            </Flex>
            <Button type="primary" onClick={onUpdate}>
              Update
            </Button>
          </Flex>
        </Header>
        <FilterContext.Provider value={filterContext}>
          <Content style={{ margin: "24px 16px", overflow: "initial" }}>
            <div
              style={{
                padding: 24,
                minHeight: 100,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Outlet />
            </div>
          </Content>
        </FilterContext.Provider>
      </Layout>
    </Layout>
  );
};

export default Root;
