import React, { useContext, useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { notification } from "antd";
// @ts-expect-error no ts declaration
import _ from "lodash";

import { FilterContext } from "../../Context";
import {
  getTokenAddress,
  getAddressLabel,
  getAllClients,
  fetchAllData,
} from "../utils";
import { CHAINS_ID_TO_NAME_MAPPING, SUPPORTED_CHAINS } from "../../chains";
import CustomTable from "../../components/Table";

const AccountSummary: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();

  const { address } = useContext(FilterContext);
  const [isLoading, setIsLoading] = useState(false);
  const [finalData, setFinalData] = useState<any[] | undefined>();
  const addressLowerCase = address?.toLowerCase();
  const allClients = getAllClients();

  useEffect(() => {
    if (!addressLowerCase || !allClients) return;
    try {
      const chainList: Array<number> = [
        SUPPORTED_CHAINS.BSC,
        SUPPORTED_CHAINS.Mantle,
        SUPPORTED_CHAINS.Arbitrum,
      ];
      let all_response = {} as Record<string, any>;
      const fetchData = async () => {
        setIsLoading(true);
        for (const chainId of chainList) {
          const client = allClients[chainId];
          const query = gql`
            query allKtc($address: String!, $skip: Int!) {
              trades(
                where: { account: $address }
                first: 1000
                orderBy: timestamp
                orderDirection: asc
                skip: $skip
              ) {
                account
                size
                sizeDelta
                realisedPnl
                collateral
                collateralToken
                fee
                isLong
                indexToken
                type
              }
            }
          `;

          const trades = await fetchAllData(client, query, "trades", {
            address: addressLowerCase,
          });
          const response = {
            account: addressLowerCase,
            collateral: 0,
            volume: 0,
            oi: 0,
            long_count: 0,
            short_count: 0,
            fee: 0,
            pnl: 0,
            max_size: 0,
            max_collateral: 0,
          } as Record<string, any>;

          trades.forEach((trade) => {
            response.max_size = Math.max(
              parseInt(trade.size) / 1e30,
              response.max_size
            );
            response.max_collateral = Math.max(
              parseInt(trade.collateral) / 1e30,
              response.max_collateral
            );
            response.volume += parseInt(trade.sizeDelta) / 1e30;
            response.collateral += parseInt(trade.collateral) / 1e30;
            response.fee += parseInt(trade.fee) / 1e30;
            response.pnl += parseInt(trade.realisedPnl) / 1e30;
            if (trade.isLong) response.long_count += 1;
            else response.short_count += 1;
            if (trade.type == "increase") {
              response.oi += parseInt(trade.sizeDelta) / 1e30;
            } else if (trade.type == "liquidate") {
              response.oi -= parseInt(trade.size) / 1e30;
              response.volume += parseInt(trade.size) / 1e30;
            } else {
              response.oi -= parseInt(trade.sizeDelta) / 1e30;
            }
          });
          all_response[CHAINS_ID_TO_NAME_MAPPING[chainId]] = response;
        }
        const finalTableData = Object.entries(all_response).map((entry) => ({
          chain: entry[0],
          account: entry[1].account,
          mark: getAddressLabel(entry[1].account),
          volume: entry[1].volume as number,
          pnl: entry[1].pnl as number,
          fee: entry[1].fee as number,
          current_oi: entry[1].oi > 1 ? entry[1].oi : (0 as number),
          max_size: entry[1].max_size as number,
          max_collateral: entry[1].max_collateral as number,
        }));

        setFinalData(finalTableData);
        setIsLoading(false);
      };
      fetchData();
    } catch (error) {
      api.error({
        message: `Failed in [AllTrade component]`,
        // @ts-expect-error any type
        description: error?.message ?? error,
      });
    }
  }, [addressLowerCase]);

  const [isLoadingSecond, setIsLoadingSecond] = useState(false);
  const [finalDataSecond, setFinalDataSecond] = useState<any[] | undefined>();

  useEffect(() => {
    if (!addressLowerCase || !allClients) return;
    try {
      const chainList: Array<number> = [
        SUPPORTED_CHAINS.BSC,
        SUPPORTED_CHAINS.Mantle,
        SUPPORTED_CHAINS.Arbitrum,
      ];
      let all_response = {} as Record<string, any>;
      const fetchData = async () => {
        setIsLoadingSecond(true);
        for (const chainId of chainList) {
          const client = allClients[chainId];
          const query = gql`
            query allKtc($token: String!, $address: String!, $skip: Int!) {
              stakeGmxes(
                where: { token: $token, account: $address }
                first: 1000
                orderBy: timestamp
                orderDirection: asc
                skip: $skip
              ) {
                account
                amount
              }
            }
          `;

          const tokenAddress = getTokenAddress("KTC", chainId);
          const stakeRes = await fetchAllData(client, query, "stakeGmxes", {
            token: tokenAddress,
            address: addressLowerCase,
          });

          const balances = _.chain(stakeRes)
            .groupBy((item: Record<string, any>) => item.account)
            .mapValues((item: Record<string, any>[]) =>
              item.reduce(
                (prev, cur) => prev + parseInt(cur?.amount ?? 0, 10),
                0
              )
            )
            .value();

          const unstakeQuery = gql`
            query allKtc($token: String!, $address: String!, $skip: Int!) {
              unstakeGmxes(
                where: { token: $token, account: $address }
                first: 1000
                orderBy: timestamp
                orderDirection: asc
                skip: $skip
              ) {
                account
                amount
              }
            }
          `;
          const unstakeRes = await fetchAllData(
            client,
            unstakeQuery,
            "unstakeGmxes",
            {
              token: tokenAddress,
              address: addressLowerCase,
            }
          );

          unstakeRes.forEach((record) => {
            if (!balances[record.account]) {
              balances[record.account] = 0;
            }
            balances[record.account] -= parseInt(record.amount, 10);
          });
          all_response[CHAINS_ID_TO_NAME_MAPPING[chainId]] = {
            account: addressLowerCase,
            ktc: balances[addressLowerCase]?balances[addressLowerCase] / 1e18:0,
          };
        }

        for (const chainId of chainList) {
          const client = allClients[chainId];
          const query = gql`
            query allKtc($token: String!, $address: String!, $skip: Int!) {
              stakeGmxes(
                where: { token: $token, account: $address }
                first: 1000
                orderBy: timestamp
                orderDirection: asc
                skip: $skip
              ) {
                account
                amount
              }
            }
          `;

          const tokenAddress = getTokenAddress("EsKTC", chainId);
          const stakeRes = await fetchAllData(client, query, "stakeGmxes", {
            token: tokenAddress,
            address: addressLowerCase,
          });

          const balances = _.chain(stakeRes)
            .groupBy((item: Record<string, any>) => item.account)
            .mapValues((item: Record<string, any>[]) =>
              item.reduce(
                (prev, cur) => prev + parseInt(cur?.amount ?? 0, 10),
                0
              )
            )
            .value();

          const unstakeQuery = gql`
            query allKtc($token: String!, $address: String!, $skip: Int!) {
              unstakeGmxes(
                where: { token: $token, account: $address }
                first: 1000
                orderBy: timestamp
                orderDirection: asc
                skip: $skip
              ) {
                account
                amount
              }
            }
          `;
          const unstakeRes = await fetchAllData(
            client,
            unstakeQuery,
            "unstakeGmxes",
            {
              token: tokenAddress,
              address: addressLowerCase,
            }
          );

          unstakeRes.forEach((record) => {
            if (!balances[record.account]) {
              balances[record.account] = 0;
            }
            balances[record.account] -= parseInt(record.amount, 10);
          });
          all_response[CHAINS_ID_TO_NAME_MAPPING[chainId]].esktc = balances[addressLowerCase]?balances[addressLowerCase] / 1e18:0;
        }


        for (const chainId of chainList) {
          const client = allClients[chainId];
          const query = gql`
            query allKtc($skip: Int!) {
              stakeGlps(
                first: 1000
                orderBy: timestamp
                orderDirection: asc
                skip: $skip
              ) {
                account
                amount
              }
            }
          `;
  
          const stakeRes = await fetchAllData(client, query, "stakeGlps", {});
  
          const balances = _.chain(stakeRes)
            .groupBy((item: Record<string, any>) => item.account)
            .mapValues((item: Record<string, any>[]) =>
              item.reduce((prev, cur) => prev + parseInt(cur?.amount ?? 0, 10), 0)
            )
            .value();
  
          const unstakeQuery = gql`
            query allKtc($skip: Int!) {
              unstakeGlps(
                first: 1000
                orderBy: timestamp
                orderDirection: asc
                skip: $skip
              ) {
                account
                amount
              }
            }
          `;
          const unstakeRes = await fetchAllData(
            client,
            unstakeQuery,
            "unstakeGlps",
            {}
          );
  
          unstakeRes.forEach((record) => {
            if (!balances[record.account]) {
              balances[record.account] = 0;
            }
            balances[record.account] -= parseInt(record.amount, 10);
          });
  
          all_response[CHAINS_ID_TO_NAME_MAPPING[chainId]].klp = balances[addressLowerCase]?balances[addressLowerCase] / 1e18:0;
        }


        const finalTableData = Object.entries(all_response).map((entry) => ({
          chain: entry[0],
          account: entry[1].account,
          mark: getAddressLabel(entry[1].account),
          ktc: entry[1].ktc as number,
          esktc: entry[1].esktc as number,
          klp: entry[1].klp as number,
        }));

        setFinalDataSecond(finalTableData);
        setIsLoadingSecond(false);
      };
      fetchData();
    } catch (error) {
      api.error({
        message: `Failed in [AllTrade component]`,
        // @ts-expect-error any type
        description: error?.message ?? error,
      });
    }
  }, [addressLowerCase]);

  return (
    <div>
      <h1> Used params - | Address |</h1>
      <CustomTable isLoading={isLoading} data={finalData} />
      <CustomTable isLoading={isLoadingSecond} data={finalDataSecond} />
      {contextHolder}
    </div>
  );
};

export default AccountSummary;
