import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { Input, Button, Flex, notification, Alert, Table } from "antd";
import useLocalStorage from "use-local-storage";

import { S3_BASE_URL, BASE_URL, USER_EMAIL_ADDRESS } from "../../constants";

const RewardsDistributionDetail = () => {
  const [curState, setCurState] = useState("loading...");
  const [list, setList] = useState<Record<string, any>[] | undefined>([]);
  const [input, setInput] = useState<string | undefined>();
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(true);
  const [hasOperatedStep, setHasOperatedStep] = useState<string | undefined>();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [userEmail] = useLocalStorage(USER_EMAIL_ADDRESS, "");

  const params = useParams();
  const [api, contextHolder] = notification.useNotification();

  const query = useQuery({
    queryKey: ["distribute_rewards_process", params.id],
    queryFn: () =>
      fetch(`${BASE_URL}get_file`, {
        method: "post",
        body: new URLSearchParams({
          fileUrl: `data/distribute_rewards/${params.id}`,
        }),
      })
        .then((res) => res.json())
        .then((res) => res.result),
    refetchInterval: 1000,
    enabled: !!params.id && shouldRefetch,
    retry: 2,
  });

  useEffect(() => {
    if (!query.data) return;
    const keys = Object.keys(query.data);
    if (!keys.length) {
      return;
    }
    // step starts from 0 and need to remove the state property from the step counting
    const curLastStep = keys.length - 2;
    const shouldPending =
      query.data[curLastStep]?.status === "pending" &&
      (query.data[curLastStep]?.options?.length > 0 ||
        query.data[curLastStep]?.key_in);
    const shouldStop = query.data[curLastStep]?.status === "terminated";
    setShouldRefetch(!shouldStop && !shouldPending);
  }, [query?.data]);

  useEffect(() => {
    if (!query.data) return;
    const finalList = [];

    const keys = Object.keys(query.data);
    // step starts from 0 and need to remove the state property from the step counting
    const curLastStep = keys.length - 2;
    for (const key of keys) {
      // @ts-ignore
      const curData = query.data[key]!;
      const hasActions =
        Number(key) === curLastStep && curData.status === "pending";

      if (key === "state") {
        setCurState(JSON.stringify(formatState(curData), null, 4));
      } else {
        const item = {
          step: key,
          status: curData.status,
          description: curData.description,
          hasActions,
          hasInput: curData.key_in,
          options: curData.options,
          retryCount: curData.retry_count,
          decision: curData.decision,
          decisionFrom: curData.decision_from,
        };
        finalList.push(item);
      }
    }
    setList(finalList);
  }, [query?.data]);

  const formatState = (state: Record<string, any>) => {
    for (const key of Object.keys(state)) {
      const v = state[key];
      if ((typeof v === "number" || /^[0-9]*$/.test(v)) && parseInt(v) > 1e10) {
        state[key] = v / 1e18;
      }
    }
    return state;
  };

  const onUpload = async (decision: string, step: string) => {
    setIsUploading(true);

    let value = decision;
    if (decision === "input") value = parseInt(input!, 10).toString();

    try {
      const res = await fetch(
        `${BASE_URL}submit_decision?file_name=${params.id}&decision=${value}&decision_from=${userEmail}`
      );
      if (res.status !== 200) {
        throw new Error();
      }
      api.success({
        message: `Upload successfully`,
      });
      setShouldRefetch(true);
      setHasOperatedStep(step);
    } catch (e) {
      api.error({
        message: `Failed to upload your action`,
        description: "Contact Lili or NJ",
      });
    } finally {
      setIsUploading(false);
    }
  };

  if (!params.id) {
    return (
      <div>
        Please check the rewards distribution list first and click the
        distribute button
      </div>
    );
  }

  const columns = [
    {
      title: "step",
      dataIndex: "step",
    },
    {
      title: "status",
      dataIndex: "status",
    },
    {
      title: "description",
      dataIndex: "description",
    },
    {
      title: "action",
      dataIndex: "action",
      render: (_: any, record: Record<string, any>) => {
        if (!record.hasActions) return null;
        if (record.hasInput) {
          return (
            <Flex
              key={record.step}
              justify="space-between"
              style={{ width: "300px" }}
              align="center"
              gap="10px"
            >
              <Input
                style={{ flex: 1 }}
                size="large"
                placeholder="only number please"
                onChange={(e) => {
                  setInput(e.currentTarget.value);
                }}
              />
              <Button
                onClick={() => {
                  onUpload("input", record.step);
                }}
                loading={isUploading}
                disabled={record.step === hasOperatedStep}
              >
                Submit
              </Button>
            </Flex>
          );
        }
        return (
          <Flex
            gap={"10px"}
            style={{ width: "300px" }}
            key={record.step}
            wrap="wrap"
          >
            {record.options?.map((optionName: string) => (
              <Button
                key={optionName}
                onClick={() => {
                  onUpload(optionName, record.step);
                }}
                loading={isUploading}
                disabled={record.step === hasOperatedStep}
              >
                {optionName}
              </Button>
            ))}
          </Flex>
        );
      },
    },
    {
      title: "retryCount",
      dataIndex: "retryCount",
    },
    {
      title: "decision",
      dataIndex: "decision",
    },
    {
      title: "decisionFrom",
      dataIndex: "decisionFrom",
    },
  ];

  return (
    <div>
      {contextHolder}
      <h1>Distribution Process...</h1>
      <h3>Current State</h3>
      <Alert
        type="info"
        message={curState}
        style={{ marginBottom: "15px", whiteSpace: "pre" }}
      />
      <Table dataSource={list} columns={columns} pagination={false} />
    </div>
  );
};

export default RewardsDistributionDetail;
