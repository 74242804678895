import React, { useContext, useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { notification } from "antd";
// @ts-expect-error no ts declaration
import _ from "lodash";

import { FilterContext } from "../../Context";
import { getAddressLabel, getClient, fetchAllData } from "../utils";
import { robotAccounts } from "../bots";
import CustomTable from "../../components/Table";

const BotTrade: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();

  const { chainId } = useContext(FilterContext);
  const [isLoading, setIsLoading] = useState(false);
  const [finalData, setFinalData] = useState<any[] | undefined>();

  const client = getClient(chainId);

  useEffect(() => {
    if (!client) return;
    try {
      const fetchData = async () => {
        const query = gql`
          query allKtc($skip: Int!) {
            trades(
              first: 1000
              orderBy: timestamp
              orderDirection: asc
              skip: $skip
            ) {
              size
              sizeDelta
              realisedPnl
              account
              collateral
              collateralToken
              fee
              isLong
              indexToken
              type
            }
          }
        `;

        setIsLoading(true);
        const trades = await fetchAllData(client, query, "trades", {});

        const response = {} as Record<string, any>;

        trades.forEach((trade) => {
          if (!(trade.account in robotAccounts)) return;

          if (!response[trade.account]) {
            response[trade.account] = {
              collateral: 0,
              volume: 0,
              oi: 0,
              long_count: 0,
              short_count: 0,
              fee: 0,
              pnl: 0,
              max_size: 0,
              max_collateral: 0,
            } as Record<string, any>;
          }

          response[trade.account].max_size = Math.max(
            parseInt(trade.size) / 1e30,
            response[trade.account].max_size
          );
          response[trade.account].max_collateral = Math.max(
            parseInt(trade.collateral) / 1e30,
            response[trade.account].max_collateral
          );
          response[trade.account].volume += parseInt(trade.sizeDelta) / 1e30;
          response[trade.account].collateral +=
            parseInt(trade.collateral) / 1e30;
          response[trade.account].fee += parseInt(trade.fee) / 1e30;
          response[trade.account].pnl += parseInt(trade.realisedPnl) / 1e30;
          if (trade.isLong) response[trade.account].long_count += 1;
          else response[trade.account].short_count += 1;
          if (trade.type == "increase") {
            response[trade.account].oi += parseInt(trade.sizeDelta) / 1e30;
          } else if (trade.type == "liquidate") {
            response[trade.account].oi -= parseInt(trade.size) / 1e30;
            response[trade.account].volume += parseInt(trade.size) / 1e30;
          } else {
            response[trade.account].oi -= parseInt(trade.sizeDelta) / 1e30;
          }
        });

        const finalTableData = Object.entries(response).map((entry) => ({
          account: entry[0],
          mark: getAddressLabel(entry[0]),
          volume: entry[1].volume as number,
          pnl: entry[1].pnl as number,
          fee: entry[1].fee as number,
          current_oi: entry[1].oi > 1? entry[1].oi:0 as number,
          max_size: entry[1].max_size as number,
          max_collateral: entry[1].max_collateral as number,
        }));

        setFinalData(finalTableData);
        setIsLoading(false);
      };
      fetchData();
    } catch (error) {
      api.error({
        message: `Failed in [AllTrade component]`,
        // @ts-expect-error any type
        description: error?.message ?? error,
      });
    }
  }, [client]);

  return (
    <div>
      <h1> Used params - | Chain |</h1>
      <CustomTable isLoading={isLoading} data={finalData} />
      {contextHolder}
    </div>
  );
};

export default BotTrade;
