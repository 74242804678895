import React, { useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import type { GetRef, TableColumnType } from "antd";
import { Button, Input, Space, Table, Flex, Spin } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
// @ts-expect-error no declare file
import Highlighter from "react-highlight-words";

type InputRef = GetRef<typeof Input>;

type TableProps = {
  isLoading: boolean;
  data?: Record<string, any>[];
};

const CustomTable: React.FC<TableProps> = ({ isLoading, data }: TableProps) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: any
  ): TableColumnType<Record<string, any>> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = data
    ? getTableColumnFromData(data).map((item) => ({
        ...item,
        ...getColumnSearchProps(item.key),
      }))
    : [];

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={data}
      pagination={{ pageSize: 50 }}
      scroll={{ x: true }}
    />
  );
};

const getTableColumnFromData = (arr: Record<string, any>[]) => {
  if (!arr.length) return [];
  const keys = [...Object.keys(arr[0])];
  const columns = keys.map((key) => {
    const vType = typeof arr[0][key];

    const sort = {};
    if (vType === "number") {
      // @ts-ignore
      sort.sorter = (a, b) => a[key] - b[key];
      // @ts-ignore
      sort.sortDirections = ["descend", "ascend"];
    }

    return {
      title: key,
      dataIndex: key,
      key,
      ...sort,
    };
  });
  return columns;
};

export default CustomTable;
