export const SUPPORTED_CHAINS = {
  BSC: 56,
  Mantle: 5000,
  Arbitrum: 42161,
};

export const CHAINS_ID_TO_NAME_MAPPING = {
  56: "BSC",
  5000: "Mantle",
  42161: "Arbitrum",
} as Record<number, string>;

export const SUBGRAPH_URLS = {
  [SUPPORTED_CHAINS.BSC]: "https://subgraph.ktx.finance/subgraphs/name/ktx",
  [SUPPORTED_CHAINS.Mantle]:
    "https://mantlesubgraph.ktx.finance/subgraphs/name/ktx",
  [SUPPORTED_CHAINS.Arbitrum]:
    "https://arbisubgraph.ktx.systems/subgraphs/name/ktx",
};

export const TOKENS = {
  [SUPPORTED_CHAINS.BSC]: {
    KTC: {
      address: "0x545356d4d69d8cD1213Ee7e339867574738751CA",
    },
    EsKTC: {
      address: "0x0BB304225860664687d5789397Bf760E9bDaE294",
    },
  },
  [SUPPORTED_CHAINS.Mantle]: {
    KTC: {
      address: "0x779f4E5fB773E17Bc8E809F4ef1aBb140861159a",
    },
    EsKTC: {
      address: "0xD7ceB9f7d75eB180296A33857e29EAE0aCfC081A",
    },
  },
  [SUPPORTED_CHAINS.Arbitrum]: {
    KTC: {
      address: "0x487f6baB6DEC7815dcd7Dfa2C44a8a17bd3dEd27",
    },
    EsKTC: {
      address: "0xbB5FF4e8C88505eCE0365aa16a6bBC9D96198141",
    },
  },
} as Record<
  string,
  Record<
    string,
    {
      address: string;
    }
  >
>;
