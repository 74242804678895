// at most 2 level's side menu is supported
export const NAV_ITEMS = {
  /**
   * 2 level side menu. The format is:
   * - parent menu label
   * -- child menu label: child menu link path
   */
  Subgraph: {
    KTC: "/subgraph/ktc",
    EsKTC: "/subgraph/esktc",
    Klp: "/subgraph/klp",
    AllTrade: "/subgraph/all_trade",
    BotTrade: "/subgraph/bot_trade",
    AccountSummary: "/subgraph/account_summary",
  },
  Action: {
    DistributeRewards: "/action/distribute_rewards",
  },
} as Record<string, Record<string, string>>;
