import { createBrowserRouter } from "react-router-dom";

import Root from "./routes/root";
import Ktc from "./routes/subgraph/Ktc";
import EsKtc from "./routes/subgraph/EsKtc";
import Klp from "./routes/subgraph/Klp";
import AllTrade from "./routes/subgraph/AllTrade";
import BotTrade from "./routes/subgraph/BotTrade";
import AccountSummary from "./routes/subgraph/AccountSummary";
import RewardsDistributionList from "./routes/action/RewardsDistributionList";
import RewardsDistributionDetail from "./routes/action/RewardsDistributionDetail";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "subgraph/ktc",
        element: <Ktc />,
      },
      {
        path: "subgraph/esktc",
        element: <EsKtc />,
      },
      {
        path: "subgraph/klp",
        element: <Klp />,
      },
      {
        path: "subgraph/all_trade",
        element: <AllTrade />,
      },
      {
        path: "subgraph/bot_trade",
        element: <BotTrade />,
      },
      {
        path: "subgraph/account_summary",
        element: <AccountSummary />,
      },
      {
        path: "action/distribute_rewards",
        element: <RewardsDistributionList />,
      },
      {
        path: "action/distribute_rewards/detail/:id",
        element: <RewardsDistributionDetail />,
      },
    ],
  },
]);
