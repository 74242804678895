import {
  ApolloQueryResult,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  DocumentNode,
  TypedDocumentNode,
} from "@apollo/client";
import { gql } from "@apollo/client";

import { TOKENS, SUBGRAPH_URLS, SUPPORTED_CHAINS } from "../chains";

// subgraph clients
const createClient = (uri: string) =>
  new ApolloClient({
    connectToDevTools: true,
    uri,
    cache: new InMemoryCache(),
  });

const BSC_CLIENT = createClient(SUBGRAPH_URLS[SUPPORTED_CHAINS.BSC]);
const MANTLE_CLIENT = createClient(SUBGRAPH_URLS[SUPPORTED_CHAINS.Mantle]);
const ARBITRUM_CLIENT = createClient(SUBGRAPH_URLS[SUPPORTED_CHAINS.Arbitrum]);
const ALL_CLIENTS = {
  [SUPPORTED_CHAINS.BSC]: BSC_CLIENT,
  [SUPPORTED_CHAINS.Mantle]: MANTLE_CLIENT,
  [SUPPORTED_CHAINS.Arbitrum]: ARBITRUM_CLIENT,
} as Record<number, any>;

export const getClient = (chainId?: number) => {
  switch (chainId) {
    case SUPPORTED_CHAINS.BSC:
      return BSC_CLIENT;
    case SUPPORTED_CHAINS.Mantle:
      return MANTLE_CLIENT;
    case SUPPORTED_CHAINS.Arbitrum:
      return ARBITRUM_CLIENT;
    default:
      return;
  }
};

export const getAllClients = () => {
  return ALL_CLIENTS;
};

export const getTokenAddress = (tokenName: string, chainId?: number) => {
  if (!chainId) {
    console.error("[getTokenAddress]: chainId is undefined");
    return;
  }
  const tokenAddress = TOKENS[chainId][tokenName].address;
  if (!tokenAddress) {
    console.error(
      `[getTokenAddress]: cannot find token ${tokenName} on chain ${chainId}`
    );
    return;
  }

  return tokenAddress.toLowerCase();
};

export const fetchAllData = async (
  client: ApolloClient<NormalizedCacheObject>,
  query: DocumentNode | TypedDocumentNode<any, Record<string, any>>,
  queryKey: string,
  queryVariables: Record<string, any>
) => {
  const res: any[] = [];

  const executor = async (skip = 0) => {
    const { data } = await client.query({
      query,
      variables: {
        ...queryVariables,
        skip,
      },
      fetchPolicy: "network-only",
    });
    const finalData = data[queryKey] ?? [];
    res.push(...finalData);
    if (finalData.length === 1000) {
      await executor(skip + 1000);
    }
  };

  await executor();
  return res;
};

export const getTableColumnFromData = (arr: Record<string, any>[]) => {
  const keys = [...Object.keys(arr[0])];
  return keys.map((key) => ({
    title: key,
    dataIndex: key,
    key,
  }));
};

export const getAddressLabel = (address: string) => {
  const addressMap = {
    "0x91ffba7e6e40faf640a8f3ab611b40ee6bfa95a4": "ophww",
    "0x26257f4eff3221d208d626429e75a591408e5d7a": "opf",
    "0xed720b8eb57276e7496b39b77dfcccde8c25d433": "opf",
    "0x8c042bb8957a7eb885d2b48b209b8ea5c2433e70": "op",
    "0x3c52d129e4a310ee508f1ce734305fb0bf97db7e": "int",
    "0x30e8746105e1e23f2977901470f228568d10c0d9": "int",
    "0x40c1445dfc945eb7f7b9277e1f07ade00471d55d": "int",
    "0xf8158f266e416c66fc4b63c997e9c6a60542e0e1": "int",
    "0xf9fcac2d4cdc3b7767a7378791b5f2c21407c74f": "int",
    "0xbcfae486660f2e633ed2db503a36cea561df8bd9": "int",
    "0x0cd893b1548f96cdd9c8be7deb15eb30a4949a29": "int",
    "0xe4ac2dbcd6531ea72da6296dbeada108c8942d21": "int",
    "0xc28b1c29556d777ec11ce9ca66c81b20d2c08477": "int",
    "0x7c4c9bc745f8dfd2c7925695a0161722f7987c7c": "int",
    "0x14cc5f1d4a78946194d78e15606f7c585e5338fd": "int",
    "0x30df5311e84e97450680399daef17de8bbdcc9cd": "tr",
  } as Record<string, string>;
  return address.toLowerCase() in addressMap
    ? addressMap[address.toLowerCase()]
    : "-";
};
